import {Component} from '@angular/core';
import {PhotoFrame} from '../../utils/photo-frame';
import {MainPage} from './main-page';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent {

  private mainPage = new MainPage();
  private readonly currentDate = new Date();

  currentGallery: PhotoFrame[] = this.getSeasonalGallery();

  private getSeasonalGallery() {
    let gallery: PhotoFrame[];
    const month = this.currentDate.getMonth() + 1;
    switch (month) {
      case 1: case 2:
        gallery = this.mainPage.valentines;
        break;
      case 3: case 4: case 5:
        gallery = this.mainPage.spring;
        break;
      case 6: case 7: case 8:
        gallery = this.mainPage.summer;
        break;
      case 9: case 10: case 11:
        gallery = this.mainPage.autumn;
        break;
      case 12:
        gallery = this.mainPage.christmas;
        break;
      default:
        gallery = this.mainPage.summer;
        break;
    }
    return gallery;
  }

}
