import {PhotoFrame} from '../../utils/photo-frame';

export class MainPage {

  private readonly pathToGallery = 'assets/main_page/';
  private readonly pathToChristmas = 'christmas/';
  private readonly pathToValentines = 'valentines/';
  private readonly pathToSpring = 'spring/';
  private readonly pathToSummer = 'summer/';
  private readonly pathToAutumn = 'autumn/';

  christmas: PhotoFrame[] = [
    {
      photoPath: this.pathToGallery + this.pathToChristmas + 'portrait1christmas.jpg',
      description: 'Składamy Wam serdeczne życzenia z okazji Świąt Bożego Narodzenia! A w Nowym Roku dużo szczęścia i radości.'
    },
    {
      photoPath: this.pathToGallery + this.pathToChristmas + 'portrait2christmas.jpg',
      // tslint:disable-next-line:max-line-length
      description: 'Znajdziesz u nas idealne warunki do pracy zdalnej. Sielska atmosfera sprzyja koncentracji i poprawia samopoczucie, nawet w bardzo pracowity dzień.'
    },
    {
      photoPath: this.pathToGallery + this.pathToChristmas + 'portrait3christmas.jpg',
      description: 'Zimowy urlop urozmaicamy gorącą sauną, kąpielą w balii, a także relaksacyjnym masażem.'
    },
    {
      photoPath: this.pathToGallery + this.pathToChristmas + 'portrait4christmas.jpg',
      description: 'Hej! Tu Gosia i Justyna, mama i córka, które goszczą Was w Siedlisku.'
    },
  ];

  valentines: PhotoFrame[] = [
    {
      photoPath: this.pathToGallery + this.pathToValentines + 'portrait1valentines.jpg',
      description: 'Spędźcie u nas romantyczny, walentynkowy urlop we dwoje. Dodatkowo możecie skorzystać z oferty masaży i spa.'
    },
    {
      photoPath: this.pathToGallery + this.pathToValentines + 'portrait2valentines.jpg',
      description: 'Zimowa aura zawitała do Siedliska. Zapraszamy do rezerwacji terminów na ferie 2025.'
    },
    {
      photoPath: this.pathToGallery + this.pathToValentines + 'portrait3valentines.jpg',
      description: 'Znajdziesz u nas idealne warunki do pracy zdalnej. Sielska atmosfera sprzyja koncentracji i poprawia samopoczucie, nawet w bardzo pracowity dzień.'
    },
    {
      photoPath: this.pathToGallery + this.pathToValentines + 'portrait4valentines.jpg',
      description: 'Zimowy urlop urozmaicamy gorącą sauną, kąpielą w balii, a także relaksacyjnym masażem.'
    },
  ];

  spring: PhotoFrame[] = [
    {
      photoPath: this.pathToGallery + this.pathToSpring + 'portrait1spring.jpg',
      description: 'Zaparaszamy do organizacji warsztatów jogowych. Dysponujemy miejscem do ćwiczeń zarówno w domu, jak i na świerzym powietrzu lub w stodole. Napisz, a prześlemy Ci ofertę.'
    },
    {
      photoPath: this.pathToGallery + this.pathToSpring + 'portrait2spring.jpg',
      description: 'Rozpoczynamy sezon na spacery i wycieczki rowerowe. Skorzystaj z pięknej okolicy i szlaków, które oferuje.'
    },
    {
      photoPath: this.pathToGallery + this.pathToSpring + 'portrait3spring.jpg',
      description: 'Piękna pogoda zawitała do naszego ogrodu. Praktykujemy kąpiele słoneczne na tarasie.'
    },
    {
      photoPath: this.pathToGallery + this.pathToSpring + 'portrait4spring.jpg',
      description: 'W Naszym ogrodzie pojawiają się pierwsze kwiaty, a przyroda budzi się do życia. Jest pięknie!'
    },
  ];

  summer: PhotoFrame[] = [
    {
      photoPath: this.pathToGallery + this.pathToSummer + 'portrait1summer.jpg',
      description: 'Zachęcamy do zabrania ze sobą rowerów, jeśli lubicie aktywne spędzanie czasu. Szlaków rowerowych i leśnych ścierzek w okolicy nie brakuje.'
    },
    {
      photoPath: this.pathToGallery + this.pathToSummer + 'portrait2summer.jpg',
      description: 'W warzywniaku rosną pyszne i zdrowe warzywa, które powędrują prosto na Wasz śniadaniowy stół.'
    },
    {
      photoPath: this.pathToGallery + this.pathToSummer + 'portrait3summer.jpg',
      description: 'Podczas pobytu możecie spędzić wieczór pod rozgwierzdżonym niebem przy blasku ogniska. '
    },
    {
      photoPath: this.pathToGallery + this.pathToSummer + 'portrait4summer.jpg',
      description: 'O tej porze roku nasz ogród jest już pięknie rozwinięty. Przekonajcie się sami. '
    },
  ];

  autumn: PhotoFrame[] = [
    {
      photoPath: this.pathToGallery + this.pathToAutumn + 'portrait1autumn.jpg',
      description: 'Zapraszamy wszystkich miłośników grzybobrania. W okolicznych lasach rośnie ich całe mnóstwo!'
    },
    {
      photoPath: this.pathToGallery + this.pathToAutumn + 'portrait2autumn.jpg',
      description: 'Zachęcamy do organizacji warsztatów jogowych. Dysponujemy przestrzenią zarówno w domu, jak i na zewnątrz.'
    },
    {
      photoPath: this.pathToGallery + this.pathToAutumn + 'portrait3autumn.jpg',
      description: 'Znajdziesz u nas idealne warunki do pracy zdalnej. Sielska atmosfera sprzyja koncentracji i poprawia samopoczucie, nawet w bardzo pracowity dzień.'
    },
    {
      photoPath: this.pathToGallery + this.pathToAutumn + 'portrait4autumn.jpg',
      description: 'Z pokoi roztacza się cudowny widok na las. A tam piękna, kolorowa, złota jesień. '
    },
  ];
}
